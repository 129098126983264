<template>
  <div class="container-fluid bg-primary">
    <div class="row">
      <div class="col">
        <div class="container-xl">
          <div class="row">
            <div class="col">
              <div class="d-flex justify-content-between p-3 pt-4">
                <Logo />
                <Menu />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

