<template>

  <div class="text-primary pb-3 text-end">
    <button class="btn btn-transparent text-light text-end p-0" data-bs-target="#collapsableMenu" data-bs-toggle="offcanvas" role="button" aria-expanded="false" aria-controls="collapsableMenu">
      <i class="bi-list display-3"></i>
    </button>

    <div class="offcanvas offcanvas-end text-start p-3 bg-primary text-light" data-bs-scroll="true" tabindex="-1" id="collapsableMenu">

      <hr class="my-1">

      <div class="d-flex justify-content-between align-items-start">
        <div class="offcanvas-body fw-light">
          <ContentList path="/" v-slot="{ list }">
            <template v-for="item in list">
              <div v-if="item.menuItem">
                <NuxtLink class="nav-link mb-2 fs-5" aria-current="page" :to="item._path">
                  <i :class="getMenuItemIconClass(item.menuItem, 'me-3')"></i>
                  {{ item.title }}
                </NuxtLink>
              </div>
            </template>
          </ContentList>
        </div>

        <button type="button" class="btn btn-outline-primary text-light" data-bs-dismiss="offcanvas" aria-label="Close">
          <i class="bi-x display-4"></i>
        </button>
      </div>

      <hr class="menu-spacing">

      <Imprint/>

    </div>
  </div>

</template>

<script setup>
  function getMenuItemIconClass(item, additionalClasses) {
    return `bi-${item.icon} ${additionalClasses}`;
  }
</script>

<style scoped>
  .menu-spacing {
    margin-top: 8rem;
  }
</style>