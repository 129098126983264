<template>
  <NuxtLink to="/" class="text-light">
    <div class="company">
      <img src="/img/logo.svg">
      <div>ubity</div>
    </div>

    <div class="slogan text-white fw-lighter">
      <span class="">Software </span>
      <span class="fw-bold text-secondary">einfach </span>
      <span class="">machen.</span>
    </div>
  </NuxtLink>
</template>

<style scoped>
  a {
    text-decoration: none;
  }

  .company {
    display: flex;
  }

  .company div {
    font-family: 'Gruppo';
    font-size: 4rem;
    line-height: 4.2rem;
    margin-left: 1.5rem;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .slogan {
    font-size: 1.35rem;
    line-height: 2rem;
  }

  img {
    margin-top: auto;
    margin-bottom: auto;
    width: auto;
    height: 4rem;
  }
</style>
